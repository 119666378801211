import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';

import { StorageInterface } from '../../interfaces/storage-interface';

@Injectable({
  providedIn: 'root'
})
export class CookiesService implements StorageInterface {

  constructor(
    private cookie: CookieService
  ) { }

  setItem(key: string, value: string): void {
    this.cookie.set(key, value)
  }

  getItem(key: string): string | null {
    return this.cookie.get(key)
  }

  removeItem(key: string): void {
    this.cookie.delete(key)
  }
}
